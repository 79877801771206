.spinner {
  display: inline-flex;
  align-items: center;
  opacity: 0;
  animation: fade-in 0.2s;
  animation-fill-mode: forwards;
}
.spinner .svg-wrapper {
  animation: spin-in 0.8s;
  animation-fill-mode: forwards;
  transform-origin: center;
}

.spinner svg {
  animation: spinner 0.8s linear infinite;
}

.text {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin-in {
  0% {
    transform: rotate(0.75turn);
  }
  100% {
    transform: rotate(1turn);
  }
}

.spinner line:first-child {
  opacity: 82.5;
}
.spinner line:nth-child(2) {
  opacity: 0;
}
.spinner line:nth-child(3) {
  opacity: 0.125;
}
.spinner line:nth-child(4) {
  opacity: 0.25;
}
.spinner line:nth-child(5) {
  opacity: 0.375;
}
.spinner line:nth-child(6) {
  opacity: 0.5;
}
.spinner line:nth-child(7) {
  opacity: 0.625;
}
.spinner line:nth-child(8) {
  opacity: 0.75;
}

@keyframes spinner {
  0% { transform: rotate(0turn); }
  12.5% { transform: rotate(0turn); }
  12.5001% { transform: rotate(0.125turn); }
  25% { transform: rotate(0.125turn); }
  25.001% { transform: rotate(0.25turn); }
  37.5% { transform: rotate(0.25turn); }
  37.5001% { transform: rotate(0.375turn); }
  50% { transform: rotate(0.375turn); }
  50.001% { transform: rotate(0.50turn); }
  62.5% { transform: rotate(0.50turn); }
  62.5001% { transform: rotate(0.625turn); }
  75% { transform: rotate(0.625turn); }
  75.001% { transform: rotate(0.75turn); }
  87.5% { transform: rotate(0.75turn); }
  87.5001% { transform: rotate(0.875turn); }
  99.999% { transform: rotate(0.875turn); }
  100% { transform: rotate(1turn); }
 }
